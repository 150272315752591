<template>
 
   <v-container>
       <div>
           <v-btn @click=printData >
Print
           </v-btn>
       </div>
        <div id ="dataapp">
        <div style="min-height: 200px"></div>

        <div class="r-width">
            <div class="main-container">
                <div class="flex-container">
                    <div>
                      
                    </div>
                    <div>
                        <h2 class="company-name">Total Ecommerce Solution</h2>
                        <p class="company-info-secondary small-text-primary">+885 8500020525 | intense@support.com |
                            supportInfinite@demo.com<br>
                            Lorem ipsume doler, sit amet | Info find set
                        </p>
                    </div>
                </div>

                <div class="secound-row">
                    <h4>MR NO. DEP2085RTG89THBS </h4>
                </div>
                <div class="title-section">
                    <h2>MONEY RECEIPT</h2>
                </div>

                <div class="border">
                    <div class="inside-border">
                        <div class="line">
                            <div class="pos-text">Job No,FHB:</div>
                            <div class="first-line-blank-space-1">DEP2085RTG89THBS</div>
                            <div class="first-line-blank-space-2">20/05/21</div>
                        </div>

                        <div class="line">
                            <div class="pos-text">Received With Thanks From:</div>
                            <div class="secound-line-blank-space-1">BEXMALL</div>
                        </div>
                        <div class="line">
                            <div class="pos-text">The Amount Of:</div>
                            <div class="third-line-blank-space-1">Three Thousand Something Something</div>
                        </div>
                        <div class="line">
                            <div class="pos-text">Against:</div>
                            <div class="fourth-line-blank-space-1">Total Ecomerce Solusion</div>
                        </div>
                        <div class="line">
                            <div class="pos-text">By Cash/Cheque/D.D/Transfer No: </div>
                            <div class="fifth-line-blank-space-1"><span
                                    class="margin-fifth-line">12525252574545852</span> <span>The City Bank Of
                                    DewanBagh</span></span></div>
                        </div>
                        <div class="bottom-between">
                            <div class="line">
                                <div class="pos-text">Date:</div>
                                <div class="sixth-line-blank-space-1">15th October, 20201</div>
                            </div>
                            <div class="line pos-text">As Part/Full/Final/Advance Payment</div>
                        </div>
                    </div>
                </div>

            <div class="bottom-between margin-top-bottom">
                <div>
                    <div class="pos-text margin-top-bottom-1">Taka: <span class="taka-box"> <span class="taka-amount"> 25000.00 </span></span></div>
                </div>
                <div>
                    <div class="border-top">For The Client</div>
                </div>
                <div>
                    <div class="border-top">For Total Ecommerce Solusion</div>
                </div>
            </div>

            </div>

        </div>
    </div>
   </v-container>

</template>


<script>
export default {
  data: () => ({}),

  methods: {
    initialize() {
      axios.get("productdetails/warehouse_report/").then((response) => {
        this.Sales = response.data.data;
      });
    },
    sumField(key) {
      // sum data in give key (property)
      return this.Sales.reduce((a, b) => a + (b[key] || 0), 0);
    },

    printData() {
      var divToPrint = document.getElementById("dataapp");
      // var newWin = window.open("");
      // newWin.document.write(divToPrint.outerHTML);
      // newWin.print();

      //newWin.close();

      var style = "<style>";
      style = style + "table {width: 100%;font: 17px Calibri;}";
      style =
        style +
        "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
      style = style + "padding: 2px 3px;text-align: center;}";
      style = style + "</style>";

      var win = window.open("", "", "height=1900,width=1900");
      win.document.write(style); //  add the style.
      win.document.write(divToPrint.outerHTML);
      win.document.close();
      win.print();
    },

    fromdate() {},
    seereport() {},
    todate() {},
  },
  mounted() {
    this.initialize();
  },
};
</script>




<style >
.r-width {
  width: 720px;
  height: 400px;
  background-color: #bfe4dc;
}

.main-container {
  padding-right: 20px;
  padding-left: 20px;
  padding-top: 15px;
}

.flex-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  font-family: "Roboto", sans-serif;
}

.logo-image {
  max-width: 65px;
}

.company-info-secondary {
  margin-top: -20px;
  text-align: right;
}

.small-text-primary {
  font-size: 13px;
}

.company-name {
  text-align: right;
}

.secound-row {
  font-family: "Roboto", sans-serif;
  margin-top: -15px;
  color: rgb(43, 71, 67);
}

.title-section {
  text-align: center;
  font-family: "Roboto", sans-serif;
  margin-top: -45px;
}

.border {
  border: 1px dashed black;
  border-radius: 10px;
}

.inside-border {
  padding: 5px;
}

.pos-text {
  font-family: "Share Tech Mono", monospace;
  margin-right: 10px;
}

.line {
  display: flex;
  margin-bottom: 5px;
  margin-top: 5px;
}

.first-line-blank-space-1 {
  min-width: 390px;
  margin-right: 20px;
  border-bottom: 1px dashed black;
  color: #5c88d2;
  font-family: "Roboto", sans-serif;
}

.first-line-blank-space-2 {
  min-width: 145px;
  border-bottom: 1px dashed black;
  color: #5c88d2;
  font-family: "Roboto", sans-serif;
}

.secound-line-blank-space-1 {
  min-width: 420px;
  border-bottom: 1px dashed black;
  color: #5c88d2;
  font-family: "Roboto", sans-serif;
}

.third-line-blank-space-1 {
  min-width: 525px;
  border-bottom: 1px dashed black;
  color: #313131;
  font-family: "Roboto", sans-serif;
}

.fourth-line-blank-space-1 {
  min-width: 580px;
  border-bottom: 1px dashed black;
  color: #313131;
  font-family: "Roboto", sans-serif;
}

.fifth-line-blank-space-1 {
  min-width: 100px;
  border-bottom: 1px dashed black;
  color: #313131;
  font-family: "Roboto", sans-serif;
}

.sixth-line-blank-space-1 {
  min-width: 100px;
  border-bottom: 1px dashed black;
  color: #313131;
  font-family: "Roboto", sans-serif;
}

.margin-fifth-line {
  margin-right: 25px;
}

.bottom-between {
  display: flex;
  justify-content: space-between;
}
.margin-top-bottom {
  margin-top: 20px;
}
.margin-top-bottom-1 {
  margin-top: 12px;
}
.taka-box {
  border: 1px solid black;
}
.taka-amount {
  font-family: "Roboto", sans-serif;
  padding: 20px;
}
.border-top {
  margin-top: 15px;
  border-top: 1px solid black;
}
</style>